import { Controller } from "stimulus"
import { createConsumer } from "@rails/actioncable"
import { comparaisonCsat } from '../components/comparison_csat'
import { chartComparisonCsat } from '../components/chart_comparison_csat'


export default class extends Controller {
  static values = { userId: String }
  static consumer = null // Keep track of the consumer instance
  static subscription = null // Propriété statique pour stocker l'abonnement

  connect() {
    if (!this.constructor.consumer) {
      this.constructor.consumer = createConsumer();
    }

    // Vérifiez si l'abonnement existe déjà
    if (!this.constructor.subscription) {
      this.constructor.subscription = this.constructor.consumer.subscriptions.create(
        { channel: "CsatCompChannel", user_id: this.userIdValue },
        { received: this.handleReceived.bind(this) }
      )
    }

    // Listen for Turbo page transitions
    document.addEventListener("turbo:before-render", this.cleanupSubscription);
  }

  disconnect() {
    this.cleanupSubscription();
    document.removeEventListener("turbo:before-render", this.cleanupSubscription);
  }

  cleanupSubscription = () => {
    if (this.constructor.subscription) {
      this.constructor.consumer.subscriptions.remove(this.constructor.subscription);
      this.constructor.subscription = null;
    }

    // Fully disconnect the consumer if no other subscriptions exist
    if (this.constructor.consumer && this.constructor.consumer.subscriptions.subscriptions.length === 0) {
      this.constructor.consumer.disconnect();
      this.constructor.consumer = null;
    }
  };

  handleReceived(data) {
    const csatCompDiv = document.getElementById("csat_comparison_dgq")
    if(csatCompDiv) {
      csatCompDiv.innerHTML = data.html
      comparaisonCsat();
      chartComparisonCsat();
    }
  }
}
