import { Controller } from "@hotwired/stimulus";
import { createConsumer } from "@rails/actioncable";
import * as bootstrap from "bootstrap";


export default class extends Controller {
  static targets = ["form", "list", "loading"];

  connect() {
    this.groupId = this.element.dataset.groupId;
    console.log(this.groupId)
    this.createSubscription();
  }

  createSubscription() {
    this.subscription = createConsumer().subscriptions.create(
      { channel: "CreateEnqueteChannel", group_id: this.groupId },
      {
        connected: () => {
          console.log(`Connecté au channel EnquetesChannel pour le groupe ID: ${this.groupId}`);
        },
        disconnected: () => {
          console.log("Déconnecté du channel EnquetesChannel");
        },
        received: (data) => {
          if (data.html && data.html != undefined) {
            this.addEnqueteToList(data.html);
            this.hideDots();
          }
        },
      }
    );
  }

  async submit(event) {
    event.preventDefault();
  
    const form = event.currentTarget;

    const actionType = form.dataset.actiontype;
  
    if (actionType === "create") {
      this.handleCreate(event);
    } else if (actionType === "edit") {
      this.handleEdit(form);
    }
  }
  
  async handleCreate(event) {  
    const formData = new FormData(this.formTarget);
    const response = await fetch(this.formTarget.action, {
      method: this.formTarget.method,
      headers: { Accept: "application/json" },
      body: formData,
    });
  
    if (response.ok) {
      const data = await response.json();
      setTimeout(() => {
        this.closeModal();
      }, 1000);
    } else {
      console.error("Erreur lors de la soumission du formulaire.");
    }
  }
  
  handleEdit(form) {
    form.submit();
  }

  addEnqueteToList(html) {
    this.listTarget.insertAdjacentHTML("afterbegin", html);
  }

  closeModal() {
    const modalElement = document.querySelector(".modal.show");
    if (modalElement) {
      const modal = bootstrap.Modal.getInstance(modalElement);
      modal.hide();
  
      const backdrop = document.querySelector(".modal-backdrop");
      if (backdrop) {
        backdrop.remove();
      }
  
      document.body.classList.remove("modal-open");
    }
  }


  hideDots() {
    const dots = document.querySelector(".dots-container");
    dots.style.display = "none";
    document.body.style.opacity = "1";
  }
}
