import { Controller } from "@hotwired/stimulus";
import { createConsumer } from "@rails/actioncable";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";

export default class extends Controller {
  static values = { downloadId: Number }
  static targets = ["downloadPdf", "modalDownloadPdfLevel", "levelBtnSubmit", "pdfDownloading", "lunchLevelModalBtn", "lunchEreputationModalBtn"]

  connect() {
    this.setupActionCable();
    this.setupDownloadButtons();
    this.listenToUrlChange();
    this.addFlatpickrToDateInputs();
  }

  // ouvrir le cable du front au back
  setupActionCable() {
    const subscriptionKey = `download-${this.downloadIdValue}`;

    // verifier si il n y a pas de souscription déjà en cours pour créer la variable qui check les souscriptions
    if (!window.downloadSubscriptions) {
      window.downloadSubscriptions = {};
    }

    // verfier s'il n y a pas de souscription déjà en cours
    if (!window.downloadSubscriptions[subscriptionKey]) {
      // si non, créer un nouveau cable (souscription)
      window.downloadSubscriptions[subscriptionKey] = createConsumer().subscriptions.create(
        { channel: "DownloadChannel", id: this.downloadIdValue },
        { received: data => this.handleReceivedData(data) }
      );
    }

    this.channel = window.downloadSubscriptions[subscriptionKey];

  }

  // verifier si le cable est toujours ouvert lors des changement de l'url
  listenToUrlChange() {
    // Ajouter un listener pour surveiller les changements d'URL
    window.addEventListener('popstate', this.handleUrlChange.bind(this));
  }

  // condition pour ouvrir la modal level avant de télécharger
  handlePdfDownloadCondition() {
    const maxDepth = this.downloadPdfTarget.getAttribute('data-max-depth');
    const selectElement = this.modalDownloadPdfLevelTarget.querySelector("#level");
    this.modalDownloadPdfLevelTarget.classList.remove("hidden");
    if (maxDepth !== '0') {
      selectElement.closest(".select-and-overview-div").classList.remove("hidden")
      const toggleSubmitButton = (event) => {
        const selectedValue = event ? event.target.value : selectElement.value;
        const sousGroupesRows = this.modalDownloadPdfLevelTarget.querySelectorAll(".sous-groupe-row-for-js");

        sousGroupesRows.forEach((row, index) => {
          row.classList.toggle("hidden", (index + 1) >= selectedValue);
        });

        this.levelBtnSubmitTarget.disabled = !selectedValue;
        this.levelBtnSubmitTarget.classList.toggle("not-clickable", !selectedValue);
      };

      toggleSubmitButton();
      selectElement.addEventListener("change", toggleSubmitButton);

    } else {
      selectElement.value = '1'
    }
  }

  // télécharger le pdf de synthèse
  downloadPdf() {
    this.downloadPdfTarget.click()
    this.closeDownloadModal();
    this.showToast();
    this.showDownloadingIcon(this.lunchLevelModalBtnTarget);
  }

  // télécharger e-reputation excel
  downloadEreputationFeedbackExcel() {
    this.showToast();
    this.showDownloadingIcon(this.lunchEreputationModalBtnTarget);
  }

  // fermer la modal level du téléchargement du pdf
  closeDownloadModal() {
    this.modalDownloadPdfLevelTarget.classList.add("hidden");
  }

   // Réinitialiser le câble si nécessaire lors du changement de l'url
   handleUrlChange() {
    const subscriptionKey = `download-${this.downloadIdValue}`;
    if (!window.downloadSubscriptions[subscriptionKey]) {
      this.setupActionCable();
    }
  }

  // gerer les données reçu du back
  handleReceivedData(data) {
    this.hideToast();
    this.putBackDefaultBtn(data);
    this.getFile(data);
  }

  // hide toast when received data
  hideToast() {
    const toastDiv = document.getElementById("toast-box")
    if (toastDiv) {
      toastDiv.classList.add("hidden");
    }
  }

  // remettre l'icone par défault des buttons de téléchargement
  putBackDefaultBtn(data) {
    const mainDiv = document.querySelector(`[data-controller="download-subscription"]`);
    const downloadButton = document.getElementById(data.response.id) || document.getElementById(data.response.secondId);
    if (downloadButton) {
      downloadButton.innerHTML = `${mainDiv.dataset.download}`;
      downloadButton.disabled = false;
    }
  }

  // appel au back pour telecharger le fichier (pdf ou excel)
  getFile(data) {
    let receivedFileName = data.response.file;
    if (receivedFileName.endsWith(".pdf")) {
      const base_url = `get_pdf/${receivedFileName}/${data.response.user}`;
      const pdf_url = data.response.pdf_url;

      // Ajouter le paramètre pdf_url manuellement
      const final_url = `${base_url}?pdf_url=${encodeURIComponent(pdf_url)}`;

      // Créer un lien et simuler un click dessus pour éviter les pops-up
      const link = document.createElement('a');
      link.href = final_url;
      // Définir l'attribut 'download' pour forcer le téléchargement
      link.setAttribute('download', receivedFileName );
      // Rendre le lien invisible
      link.style.display = 'none';
      // Ajouter l'élément <a> au document et cliquer dessus
      document.body.appendChild(link);
      link.click();

      // Supprimer l'élément <a> après le clic
      document.body.removeChild(link);
    } else {
      const excel_base_url = `get_excel/${receivedFileName}/${data.response.user}`;
      const excel_url = data.response.excel_url;

      // Ajouter le paramètre excel_url manuellement
      const final_url = `${excel_base_url}?excel_url=${encodeURIComponent(excel_url)}`;
      window.location.href = final_url;
    }
  }

  // lister les ids des buttons de téléchargement
  setupDownloadButtons() {
    const buttonIds = [
      "btn-download-report",
      "btn-download-group-meal",
      "base-client-btn",
      "rgpd-btn",
      "btn-download-group-meal-resto-price",
      "download-question-enquetes-btn",
      "download-group-meal-resto-admin-btn",
      "download-enquetes-multisites-btn",
      "download-e-reputation-btn",
      "download-enquetes-return-rate-btn"
    ];

    buttonIds.forEach(btnId => {
      this.getDownloadBtnById(btnId).then(downloadBtn => {
        this.setupDownloadButton(downloadBtn);
      });
    });
  }

  // chercher le button du DOM à partir de son id
  getDownloadBtnById(btnId) {
    return new Promise((resolve) => {
      setTimeout(() => {
        const downloadBtn = document.getElementById(btnId);
        resolve(downloadBtn);
      }, 50);
    });
  }

  // afficher la notification de téléchargement
  setupDownloadButton(btn) {
    if (btn) {
      this.addDownloadingIcon(btn);
      btn.addEventListener("click", ()=> {
        this.showToast();
      })
    }
  }

  // ajouter l'icone de téléchargement en cours
  addDownloadingIcon(btn) {
    btn.addEventListener("click", () => {
      this.showDownloadingIcon(btn);
    });
  }

  showDownloadingIcon(btn) {
    const mainDiv = document.querySelector(`[data-controller="download-subscription"]`);
    btn.innerHTML = `<i class="fa-solid fa-download fa-beat-fade" style="color: #63E6BE;"></i>&nbsp <i class="ms-1" style="color: #63E6BE;">${mainDiv.dataset.downloading}</i>`;
    setTimeout(() => {
      btn.disabled = true;
    }, 50);
  }

  // creation de la notification en js (toast)
  showToast() {
    const toastDiv = document.getElementById("toast-box");
    const mainDiv = document.querySelector(`[data-controller="download-subscription"]`);
    if (toastDiv) {
      toastDiv.classList.remove("hidden");
      if (toastDiv.innerHTML.trim() === "") {
        let toast = document.createElement('div');
        toast.classList.add('toast_custom');
        toast.innerHTML = `<i class="fa-solid fa-download"></i><h5>${mainDiv.dataset.toastWait}</h5>`;
        toastDiv.appendChild(toast);
        setTimeout(() => {
          toast.remove();
        }, 3000);
      }
    }
  }

  // configuration des inputs date dans la page des téléchargements
  addFlatpickrToDateInputs() {
    const startDateFlatpickr = flatpickr(".start-date", {
      dateFormat: "d-m-Y",
      onChange: (selectedDates, dateStr, instance) => {
        this.endDateFlatpickr.set("minDate", dateStr);
      }
    });

    this.endDateFlatpickr = flatpickr(".end-date", {
      dateFormat: "d-m-Y",
    });

    if(startDateFlatpickr.calendarContainer && this.endDateFlatpickr.calendarContainer) {
      startDateFlatpickr.calendarContainer.classList.add("custom-flatpickr-calendar");
      this.endDateFlatpickr.calendarContainer.classList.add("custom-flatpickr-calendar");
    }

  }
}
