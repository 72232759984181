const destroyEnquete = () => {
    $('.btn-delete-enquete').off('click').on('click', function(event) {
        event.preventDefault();

        const $btn = $(this).find("a");
        const $div = $(this).closest(".subcontainer-seeting-enquete");
        const confirmationText = $btn.data("custom-confirm")

        const confirmation = confirm(confirmationText);

        if (confirmation) {
            $.ajax({
                url: $btn.attr("href"),
                type: "DELETE",
                success: function(response) {
                    console.log("SUCCESS", response);
                    $div.remove();
                },
                error: function(error) {
                    console.error("Erreur :", error);
                }
            });
        }
    });
};

export { destroyEnquete };
